<template>
    <div>
        <st-modal
            id="st-complain-classify-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="xxl"
            hideable
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                    {{ title }} {{record.identification_number}}
                </p>
            </template>
            <template #body>
                <ComplainClassifyForm
                    ref="complain-classify-form"
                    :id="record.id"
                    :closeStatus="closeStatus"
                    @cancelForm="hide"
                    @preview="preview"
                >
                </ComplainClassifyForm>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="doSubmit"
                    >
                      {{ buttonText }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import ComplainClassifyForm from './ComplainClassifyForm.vue';
import { mapGetters } from "vuex";
export default {
    name: 'ComplainClassifyModal',
    components: {
        ComplainClassifyForm
    },
     props: {
        closeStatus: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            record: 'complain/form/record',
        }),
        title() {
            if (this.closeStatus === 'dismissed') return  this.$t('COMPLAIN.FORM.TITLE_CLASSIFY');
            if (this.closeStatus === 'resolved') return  this.$t('COMPLAIN.FORM.TITLE_RESOLVED');
            return '';
        },
        buttonText() {
            if (this.closeStatus === 'dismissed') return  this.$t('COMPLAIN.FORM.CLASSIFY_BUTTON');
            if (this.closeStatus === 'resolved') return  this.$t('COMPLAIN.FORM.RESOLVED_BUTTON');
            return '';
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        doSubmit() {
            this.$refs['complain-classify-form'].submitForm();
        },
        preview(dismissed_comment) {
            this.$emit('preview', {record: this.record, message: dismissed_comment});
        }
    }
};
</script>

import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class ComplainPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.complainRead,
        );
        this.readStaff =  permissionChecker.match(
            Permissions.values.complainStaffRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.complainCreate,
        );
        this.view = permissionChecker.match(
            Permissions.values.complainView,
        );
        this.edit = permissionChecker.match(
            Permissions.values.complainEdit,
        );
        this.resolve = permissionChecker.match(
            Permissions.values.complainResolve,
        )
        this.comment = permissionChecker.match(
            Permissions.values.complainStaffComment,
        )
    }
}

import ApiService from '@/shared/services/api.service';

export class ComplainService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/complains', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/complains/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static findForStaff(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/staff-complains/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/complains?echo=true', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/complains/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static updateStaff(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/staff-complains/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static listStaff(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/staff-complains/all', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }
    static download(token, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/files/${id}`, {
                'x-file-access-token': token,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getActivities(id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`user-messages/identification/${id}`, {
                params: {
                    sort: '-created_date',
                    limit: 100
                }
            })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
        });
    }

    static sendStaffComment(params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`staff-complains/${params.id}/comment`, { ...params.payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findNotificationTemplate(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/notifications/templates/get/body', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getUploadToken() {
        return new Promise((resolve, reject) => {
            ApiService.get('/complains/token')
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static getAssignedUsers() {
        const params = { 
            params: { 
                group: ['assigned_user_name', 'assigned_user_id'],
                sort: 'assigned_user_name',
                envelope: false,
                limit: 100,
            },
        };
        return new Promise((resolve, reject) => {
            ApiService.query('/staff-complains/all', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}

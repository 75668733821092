
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { ComplainService } from '@/modules/complain/complain-service';

export class AssginedUserField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            ComplainService.getAssignedUsers,
            (record) => {
                if (!record) {
                    return '';
                }
                return {
                    value: record.assigned_user_id,
                    text: record.assigned_user_name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}

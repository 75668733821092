import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';
import { storeAsync } from '@/app-module';

export class ResponseTemplatesFields {

    static relationToOne(name, label, rules) {
        return new RelationToOneField(
            name,
            label,
            async () => {
                const store = storeAsync();
                await store.dispatch('auth/doWaitUntilInit');
                const complainPermissions = store.getters['complain/list/complainPermissions'];
                return new Promise((resolve) => {
                    if (complainPermissions.hasPermissionToStaffComplains) {
                        ApiService.query('response-templates', {
                            params: {limit: 100, query: `category eq 'complains'`,},
                        })
                        .then(({ data }) => {
                            resolve(data.data);
                        });
                    } else {
                        resolve([]);
                    }
                })
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.title,
                    record
                };
            },
            rules,
        )
    }
}

<template>
    <div>
        <Loader v-if="isLoading" :text="loaderMessage" />
        <div class="form"
            id="st_complain_form"
            ref="st_complain_form"
        >
            <b-card class="st-section mt-6">
                <div class="st-section-body row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>
                                {{ fields.complain_type_id.label }} *
                            </label>
                            <st-select
                                v-model="model[fields.complain_type_id.name]"
                                :ref="fields.complain_type_id.name"
                                :field="fields.complain_type_id"
                                :formValidation="fv"
                                :disabled="viewMode"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group st-form-group-textarea">
                            <label>
                                {{ fields.description.label }} *
                            </label>
                            <b-form-textarea
                                :ref="fields.description.name"
                                :name="fields.description.name"
                                :placeholder="fields.description.placeholder"
                                v-model="model[fields.description.name]"
                                class="st-form-textarea"
                                rows="10"
                                no-resize
                                :disabled="viewMode"
                            >
                            </b-form-textarea>
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card class="st-section mt-6"  v-if="showResponseSection">
                <div class="st-section-header">
                    <div class="st-section-header-title">{{ $t('COMPLAIN.FORM.RESPONSE') }}</div>
                </div>
                <div class="st-section-body">
                    <div v-html="record.custom_comment"></div>
                </div>
            </b-card>
            <b-card class="st-section mt-6">
                <div class="st-section-header">
                    <div class="st-section-header-title">{{ $t('COMPLAIN.FORM.ATLAS_PREVIEW_SECTION_TITLE') }}</div>
                    <div class="st-section-header-actions" v-if="location">
                        <st-button
                            :disabled="!isViewGoogleMapsButtonEnabled"
                            :callback="viewInGoogleMaps"
                        >
                            <i class="fas fa-map"/>
                            {{ $t('COMPLAIN.FORM.VIEW_GOOGLE_MAPS_BUTTON') }}
                        </st-button>
                    </div>
                </div>
                <div class="st-section-body">
                    <location-map
                        v-if="showMap"
                        :viewMode="viewMode"
                        :address="address"
                        :location="location"
                        @selectLocation="selectLocation"
                    />
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group st-form-group-textarea">
                            <label>
                                    {{ fields.territorial_administrative_unit_id.label }} *
                            </label>
                            <st-autocomplete
                                v-model="model[fields.territorial_administrative_unit_id.name]"
                                :options="fields.territorial_administrative_unit_id.options"
                                :ref="fields.territorial_administrative_unit_id.name"
                                :name="fields.territorial_administrative_unit_id.name"
                                :disabled="viewMode"
                                :fields="fields"
                                :model="model"
                                :formValidation="fv"
                            ></st-autocomplete>
                        </div>
                    </div>
                </div>

                <div class="mt-6">
                    <div class="form-group st-form-group-textarea">
                        <label>
                            {{ fields.address_details.label }}
                        </label>
                        <b-form-textarea
                            :ref="fields.address_details.name"
                            :name="fields.address_details.name"
                            :placeholder="fields.address_details.placeholder"
                            v-model="model[fields.address_details.name]"
                            class="st-form-textarea"
                            rows="10"
                            no-resize
                            :disabled="viewMode"
                        >
                        </b-form-textarea>
                    </div>
                </div>
            </b-card>
            <div class="row">
                <div class="col-12 col-md-8">
                    <b-card class="st-section mt-6">
                        <div class="st-section-header">
                            {{ $t('COMPLAIN.FORM.USER_DETAIL_SECTION_TITLE') }}
                        </div>
                        <div class="st-section-body row">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>
                                        {{ fields.contact_user_first_name.label }} *
                                    </label>
                                    <st-input-text
                                        :ref="fields.contact_user_first_name.name"
                                        :name="fields.contact_user_first_name.name"
                                        :disabled="viewMode || useMyProfileData"
                                        v-model="model[fields.contact_user_first_name.name]"
                                    />
                                </div>
                                <div class="form-group">
                                    <label>
                                        {{ fields.contact_user_last_name.label }} *
                                    </label>
                                    <st-input-text
                                        :ref="fields.contact_user_last_name.name"
                                        :name="fields.contact_user_last_name.name"
                                        :disabled="viewMode || useMyProfileData"
                                        v-model="model[fields.contact_user_last_name.name]"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label>
                                        {{ fields.contact_user_email.label }} *
                                    </label>
                                    <st-input-text
                                        :ref="fields.contact_user_email.name"
                                        :name="fields.contact_user_email.name"
                                        :disabled="viewMode || useMyProfileData"
                                        v-model="model[fields.contact_user_email.name]"
                                    />
                                </div>
                                <div class="form-group">
                                    <label>
                                        {{ fields.contact_user_phone.label }} *
                                    </label>
                                    <st-input-text
                                        :ref="fields.contact_user_phone.name"
                                        :name="fields.contact_user_phone.name"
                                        :disabled="viewMode || useMyProfileData"
                                        v-model="model[fields.contact_user_phone.name]"
                                        phone-validation
                                    />
                                </div>
                            </div>
                            <div class="col-12">
                                <b-form-checkbox
                                    id="complain_check"
                                    v-model="model[fields.use_profile_data.name]"
                                    size="lg"
                                    @change="onChangeUseMyProfileData"
                                    :disabled="viewMode"
                                >
                                    {{ fields.use_profile_data.label }}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div class="col-12 col-md-4">
                    <b-card class="st-section mt-6">
                        <div class="st-section-header">
                            {{ documentsSectionTitle }}
                        </div>
                        <div class="st-section-body">
                            <div class="st-files-container">
                                <template v-if="documents.length">
                                    <div
                                        v-for="(doc, index) in documents" :key="index"
                                        class="st-file-item">
                                        <div v-if="viewMode">
                                            <div v-if="doc.file_name" class="text-truncate">{{ doc.file_name}}</div>
                                            <div v-else>{{ `file-${index}` }}</div>
                                        </div>
                                        <div v-else>
                                            <div class="text-truncate">{{ doc.name}}</div>
                                        </div>
                                        <i class="st-remove-file fas fa-trash" v-if="!viewMode" @click="removeFile(index)"></i>
                                        <i class="st-view-file fas fa-eye" v-if="viewMode" @click="viewFile(doc)"></i>
                                    </div>
                                </template>
                                <div v-if="viewMode && !documents.length">{{ $t('COMPLAIN.FORM.EMPTY_DOCUMENT_LABEL') }}</div>
                            </div>
                            <b-form-file
                                v-show="false"
                                multiple
                                @change="uploadFile"
                                id="file-default"
                                accept=".jpg, .jpeg, .png,.pdf,.zip"
                                ref="upload_documents"
                            ></b-form-file>
                            <st-button
                                v-if="!viewMode && uploadedFilesLimit"
                                variant="primary"
                                customClass="st-btn-upload"
                                class="px-6 mt-4"
                                :callback="pickDocuments"
                            >{{ textButton }}
                            </st-button>
                        </div>
                    </b-card>
                </div>
            </div>
            <portal to="complain-toolbar">
                <st-button
                    variant="secondary"
                    v-can:create="'complain'"
                    :callback="doSubmit"
                    :disabled="!!complainId"
                >
                    <i class="fa fa-paper-plane"></i>
                    {{ $t('COMPLAIN.FORM.SUBMIT_BUTTON') }}
                </st-button>
                <st-button
                    v-can:edit="'complain'"
                    variant="light"
                    customClass="text-danger"
                    v-show="enableClassify"
                    :callback="doClassify"
                >
                    <i class="fas fa-archive text-danger"></i>
                    {{ $t('COMPLAIN.FORM.CLASSIFY_BUTTON') }}
                </st-button>
                <st-button
                    v-can:edit="'complain'"
                    variant="secondary"
                    v-show="enableResolve"
                    :callback="doSendResolve"
                >
                    <i class="fa fa-paper-plane"></i>
                    {{ $t('COMPLAIN.FORM.RESOLVE_BUTTON') }}
                </st-button>

            </portal>

            <div class="d-flex mt-6 justify-content-end">
                <st-button
                    variant="secondary"
                    v-can:create="'complain'"
                    :callback="doSubmit"
                    :disabled="!!complainId"
                >
                    <i class="fa fa-paper-plane"></i>
                    {{ $t('COMPLAIN.FORM.SUBMIT_BUTTON') }}
                </st-button>
            </div>
            <complain-classify-modal ref="complain-classify-modal" @preview="preview" :closeStatus="closeStatus"/>
            <complain-preview-modal ref="complain-preview-modal" :previewData="previewData"/>
        </div>
    </div>
</template>

<script>
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { ComplainModel } from '@/modules/complain/complain-model';
import { mapActions, mapGetters } from "vuex";
import /* webpackChunkName: "locationmap" */ LocationMap from "@/modules/complain/components/LocationMap.vue";
import ComplainClassifyModal from "@/modules/complain/components/ComplainClassifyModal.vue";
import ComplainPreviewModal from "@/modules/complain/components/ComplainPreviewModal.vue";
import { ComplainPermissions } from '@/modules/complain/complain-permissions';
import Notify from '@/shared/utils/notify';

const { fields } = ComplainModel;

const formSchema = new FormSchema([
    fields.id,
    fields.identification_number,
    fields.complain_type_id,
    fields.locality_id,
    fields.address,
    fields.address_details,
    fields.description,
    fields.reporting_user_id,
    fields.reporting_user_email,
    fields.reporting_user_first_name,
    fields.reporting_user_last_name,
    fields.reporting_user_full_name,
    fields.reporting_user_phone,
    fields.contact_user_email,
    fields.contact_user_first_name,
    fields.contact_user_last_name,
    fields.contact_user_full_name,
    fields.contact_user_phone,
    fields.use_profile_data,
    fields.status,
    fields.territorial_administrative_unit_id,
]);

export default {
    name: 'ComplainDetail',
    components: {
        LocationMap,
        ComplainClassifyModal,
        ComplainPreviewModal
    },
    props: {
        complainId: String,
    },
    data: () => ({
        rules: formSchema.rules(),
        model: null,
        documents: [],
        location: null,
        address: '',
        fv: null,
        previewData: {},
        closeStatus: ''
    }),
    computed: {
        ...mapGetters({
            record: 'complain/form/record',
            filters: 'complain/list/filters',
            currentUser: 'auth/currentUser',
            isStaff: 'auth/isStaff',
            fileDownloadAccessToken: 'complain/form/fileDownloadAccessToken',
            uploadAccessToken: 'complain/form/uploadAccessToken',
            appConfig: 'shared/appConfig',
            loading: "shared/loading",
        }),
        fields() {
            return fields;
        },
        viewMode() {
            return !!this.complainId;
        },
        useMyProfileData() {
            return !!this.model.use_profile_data;
        },
        documentsSectionTitle() {
            return this.viewMode ? this.$t('COMPLAIN.FORM.VIEW_DOCUMENTS_SECTION_TITLE') : this.$t('COMPLAIN.FORM.DOCUMENTS_SECTION_TITLE');
        },
        enableClassify() {
            const status = this.model?.status;
            return !['resolved', 'dismissed', 'rejected'].includes(status);
        },
        uploadedFilesLimit() {
            return this.documents.length <= this.appConfig.MAX_COMPLAINT_IMAGES;
        },
        enableResolve() {
            const status = this.model?.status;
            return !['resolved', 'dismissed', 'rejected'].includes(status);
        },
        isViewGoogleMapsButtonEnabled() {
            return this.location;
        },
        showMap() {
            return this.location || !this.isStaff;
        },
        hasPermissionToStaffComplains() {
            return new ComplainPermissions(this.currentUser).readStaff;
        },
        showResponseSection() {
            if (!this.viewMode) {
                return false;
            }
            return (this.record?.status === 'dismissed' || this.record?.status === 'resolved') &&
                this.record?.custom_comment
        },
        isLoading() {
            return (
                this.loading["complain/create"] ||
                this.loading["complain/upload"] ||
                this.loading["complain/update"] ||
                this.loading["complain/notificationTemplate"]
            );
        },
        loaderMessage() {
            return this.loading["complain/upload"] ? this.$t('APPLICATION.NOTIFIERS.UPLOADING_FILES') : '';
        },
        textButton() {
            return this.appConfig.MAX_COMPLAINT_IMAGE_SIZE_MB ?
                this.$t("GENERAL.BUTTON.UPLOAD_MAX_FILE_SIZE", [this.appConfig.MAX_COMPLAINT_IMAGE_SIZE_MB]) :
                this.$t("COMPLAIN.FORM.UPLOAD_DOCUMENT_BUTTON");
        }
    },
    methods: {
        ...mapActions({
            doCreate: 'complain/form/create',
            doUpdate: 'complain/form/update',
            doFetch: 'complain/list/doFetch',
            doClassification: 'complain/form/classification',
            doUpload: 'complain/form/upload',
            doDownload: 'complain/form/download',
            doResolve: 'complain/form/resolve',
            getUploadToken: 'complain/form/getUploadToken',
        }),
        async doSubmit() {
            if (!this.location) {
                this.$alert({
                    type: 'warning',
                    title: this.$t('COMPLAIN.NOTIFIERS.LOCATION_TITLE'),
                    text: this.$t('COMPLAIN.NOTIFIERS.LOCATION_TEXT'),
                    showConfirmButton: false,
                });
                return;
            }

            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const localities = this.fields.territorial_administrative_unit_id.options;
                const selectedLocality = localities.find( locality => locality.value === this.model.territorial_administrative_unit_id);
                const userDetails = {
                    reporting_user_id: this.currentUser.user_id,
                    reporting_user_first_name: this.currentUser.first_name,
                    reporting_user_last_name: this.currentUser.last_name,
                    reporting_user_email: this.currentUser.email,
                    reporting_user_phone: this.currentUser.phone,
                    contact_user_first_name: this.model.contact_user_first_name,
                    contact_user_last_name: this.model.contact_user_last_name,
                    contact_user_email: this.model.contact_user_email,
                    contact_user_phone: this.model.contact_user_phone,
                    description: this.model.description,
                    address: this.model.address,
                    address_details: this.model.address_details,
                    locality_id: this.model.locality_id,
                    locality_name: this.model.locality_name,
                    location: this.location,
                    complain_type_id: this.model.complain_type_id,
                    complain_type_name: fields.complain_type_id.options.find((el) => el.value === this.model.complain_type_id)?.text || '',
                    use_profile_data: this.model.use_profile_data,
                    uat: {
                        locality_id: selectedLocality.value,
                        locality_name: selectedLocality.text
                    }
                };

                let formData = new FormData();
                for( let i = 0; i < this.documents.length; i++ ) {
                    let file = this.documents[i];
                    formData.append('files', file);
                }

                if (this.documents.length) {
                    this.doUpload({files: formData, uploadAccessToken: this.uploadAccessToken})
                    .then((files) => {
                        const complainFiles = files.map((el) => {
                            return {
                                file_id: el.id,
                                file_name: el.initialFilename
                            }
                        });
                        userDetails.files = complainFiles;
                        this.doCreate(userDetails)
                        .then((data) => {
                            this.showSuccessModal(data.identification_number);
                        });
                    });
                } else {
                    this.doCreate(userDetails)
                    .then((data) => {
                        this.showSuccessModal(data.identification_number);
                    });
                }
            }
        },
        showSuccessModal(id) {
            const title = this.$t('COMPLAIN.NOTIFIERS.CREATED_SUCCESS_TITLE');
            const text = `${this.$t('COMPLAIN.NOTIFIERS.CREATED_SUCCESS_TEXT', {number: id})}`;
            this.$alert({
                type: 'success',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
                callback: () => {
                    this.$router.push({name: 'complain'});
                },
            });
        },
        onChangeUseMyProfileData() {
            if (this.useMyProfileData) {
                this.populateUserDetailsWithCurrentUser();
                return;
            }
            this.clearUserDetails();
        },
        populateUserDetailsWithCurrentUser() {
            const userDetails = {
                contact_user_first_name: this.currentUser.first_name,
                contact_user_last_name: this.currentUser.last_name,
                contact_user_email: this.currentUser.email,
                contact_user_phone: this.currentUser.phone_number,
            };
            this.model = {
                ...this.model,
                ...userDetails,
            };
        },
        clearUserDetails() {
            this.model.contact_user_first_name = '';
            this.model.contact_user_last_name = '';
            this.model.contact_user_email = '';
            this.model.contact_user_phone = '';
        },
        pickDocuments() {
            this.$refs.upload_documents.$el.childNodes[0].click();
        },
        uploadFile(ev) {
            const uploaded = ev.target.files;
            this.validate(uploaded)
            if (!this.validate(uploaded)) {
                Notify("error", this.errorMessage);
                return false;
            }
            this.documents = [
                ...this.documents,
                ...uploaded,
            ];
            ev.target.value = null;
            return;
        },
        validate(files) {
            if (!this.validateFilesLength(files)) return false;
            if (!this.validateFilesSize(files)) return false;
            return true;
        },
        validateFilesLength(files) {
            const totalFiles = files.length + this.documents.length;
            if (totalFiles <= this.appConfig.MAX_COMPLAINT_IMAGES) return true;
            this.errorMessage = this.$t("VALIDATION.MAX_UPLOADED_FILES", {
                max: this.appConfig.MAX_COMPLAINT_IMAGES,
            });
            return false;
        },
        validateFilesSize(files) {
            let fileObj = '';
            const isValid = !Object.values(files).some((file) => {
                const fileSize = file.size / 1024 / 1024; // MB
                fileObj = file;
                return fileSize >= this.appConfig.MAX_COMPLAINT_IMAGE_SIZE_MB
            });
            if (isValid) return true;
            this.errorMessage = this.$t("VALIDATION.UPLOADED_FILE_SIZE_NAME", { name: fileObj.name });
            return false;
        },
        removeFile(index) {
            this.$delete(this.documents, index);
        },
        onChange(field) {
            this.fv.revalidateField(field.name, true);
        },
        viewFile(doc) {
            window.open(`${window.VUE_APP_API_URL}/files/${doc.file_id}?token=${this.fileDownloadAccessToken}`, '_blank');
        },
        doClassify() {
            this.closeStatus = 'dismissed';
            this.$refs['complain-classify-modal'].show();
        },
        doSendResolve() {
            this.closeStatus = 'resolved';
            this.$refs['complain-classify-modal'].show();
        },
        selectLocation(data) {
            this.model[this.fields.address.name] = data.address;
            this.model[this.fields.locality_id.name] = '54984';
            this.model[this.fields.locality_name.name] = data.attributes.City;
            const location = {
                latitude: data.location.latitude,
                longitude: data.location.longitude,
                latitude_stereo70: data.location.latitude_stereo70,
                longitude_stereo70: data.location.longitude_stereo70
            };
            this.location = location;
        },
        viewInGoogleMaps() {
            const location = this.location;
            const url = `https://www.google.com/maps/?q=${location.latitude},${location.longitude}`;
            window.open(url, '_blank').focus();
        },
        preview(data) {
            this.previewData = data;
            this.$refs['complain-preview-modal'].show();
        }
    },
    created() {
        const userDetails = {
            reporting_user_id: this.currentUser.user_id,
            reporting_user_first_name: this.currentUser.first_name,
            reporting_user_last_name: this.currentUser.last_name,
            reporting_user_email: this.currentUser.email,
            reporting_user_phone: this.currentUser.phone_number,
            contact_user_first_name: this.currentUser.first_name,
            contact_user_last_name: this.currentUser.last_name,
            contact_user_email: this.currentUser.email,
            contact_user_phone: this.currentUser.phone_number,
            use_profile_data: true,
        };
        const record = this?.record || userDetails;
        if (record?.files) {
            this.documents = record.files;
        }

        if (record.location && !!record.location?.latitude) {
            this.location = record.location;
        }
        if (record.address) {
            this.address = record.address;
        }
        if (record?.uat) {
            record.territorial_administrative_unit_id = record.uat.locality_id;
        }
        this.model = formSchema.initialValues(record);
        if (!record?.id) {
            this.getUploadToken();
        }
    },
    mounted() {
        this.fv = createFormValidation('st_complain_form', this.rules);
    }
}
</script>

<template>
    <div>
        <st-modal
            id="st-complain-preview-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="lg"
            hideable
            ref="modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                   {{ $t('COMPLAIN.FORM.PREVIEW_TITLE') }}
                </p>
            </template>
            <template #body>
                <div>
                    <p>{{notificationTemplateText}}</p>
                    <div v-html="previewData.message"></div>
                </div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
export default {
    name: 'ComplainPreviewModal',
    props: {
        previewData: {
            type: Object,
            required: true
        },
    },
    computed: {
        notificationTemplateText () {
            return this.$t('COMPLAIN.FORM.NOTIFICATION_TEMPLATE_TEXT',
                {
                    type: this.previewData.record.complain_type_name,
                    number: this.previewData.record.identification_number,
                    user: this.previewData.record.namereporting_user_full_name
                }
            );
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
    }
};
</script>

<template>
    <div>
        <form class="form"
            novalidate="novalidate"
            id="st_complain_classify_form"
        >
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.response_templates.label }} *
                        </label>
                        <st-select
                            v-model="model[fields.response_templates.name]"
                            :ref="fields.response_templates.name"
                            :field="fields.response_templates"
                            :formValidation="fv"
                        >
                        </st-select>
                    </div>
                </div>
            </div>
            <div v-show="model[fields.response_templates.name]">
                <div class="form-group" >
                    <label v-html="dismissedCommentLabel"></label>
                    <st-richtext-editor
                        formId="st_complain_classify_form"
                        v-model="model.dismissed_comment"
                        :uploadToken="uploadAccessToken"
                    />
                </div>
            </div>
        </form>
         <st-button
            v-if="false"
            v-show="model[fields.response_templates.name]"
            variant="link"
            class="font-size-h6 py-3"
            :callback="preview"
        > {{ $t('GENERAL.PREVIEW') }}
        </st-button>
    </div>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { FormSchema } from '@/shared/form/form-schema';
import { ComplainModel } from '@/modules/complain/complain-model';
import StRichtextEditor from '@/shared/components/StRichtextEditor';

const { fields } = ComplainModel;
const formSchema = new FormSchema([
    fields.response_templates,
    fields.dismissed_comment,
])
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'ComplainClassifyForm',
    components: { StRichtextEditor },
    props: {
        id: {
            type: String,
        },
        closeStatus: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            fields,
            fv: null,
            model: null,
            dismissedCommentLabel: ''
        };
    },
    computed: {
        ...mapGetters({
            uploadAccessToken: 'complain/form/uploadAccessToken',
        }),
    },
    watch: {
        'model.response_templates'(newValue, oldValue)  {
            if (newValue === oldValue) return false;
            this.model.dismissed_comment =  this.getSelectedResponseTemplate(newValue).message;
            this.getNotificationTemplate(this.closeStatus)
            .then((data)=> {
                if (data?.message) {
                    const message = data.message.split('{{{custom_comment}}}');
                    this.dismissedCommentLabel = message[0] + '*';
                } else {
                    this.dismissedCommentLabel = this.fields.dismissed_comment.label + '*';
                }
            })
            .catch(()=> {
                this.dismissedCommentLabel = this.fields.dismissed_comment.label + '*';
            })
        }
    },
    mounted() {
        this.fv = createFormValidation('st_complain_classify_form', this.rules);

        this.getUploadToken();
    },
    created() {
        this.model = formSchema.initialValues({});
    },
    methods: {
        ...mapActions({
            doClassification: 'complain/form/classification',
            getNotificationTemplate: 'complain/form/doFindNotificationTemplate',
            doResolve: 'complain/form/resolve',
            getUploadToken: 'complain/form/getUploadToken',
        }),
        hide() {
            this.$emit('cancelForm');
        },
        async submitForm() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const payload = {
                    id: this.id,
                    values: {
                        status: this.closeStatus,
                        custom_comment: this.model.dismissed_comment
                    }
                }
                if (this.closeStatus === 'dismissed') {
                    this.doClassification(payload).then(() => {
                        this.hide();
                        this.$router.push({ name: 'complain' });
                    });
                }
                if (this.closeStatus === 'resolved') {
                    this.doResolve(payload).then(() => {
                        this.hide();
                        this.$router.push({ name: 'complain' });
                    });
                }
               ;
            }
        },
        getSelectedResponseTemplate(id) {
            const responseTemplateOptions = this.fields['response_templates'].options;
            return responseTemplateOptions.find((el) => el.value === id)?.record;
        },
        preview() {
            this.$emit('preview', this.model.dismissed_comment);
        }
    }
};
</script>

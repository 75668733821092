import ApiService from '@/shared/services/api.service';

export class ComplainTypeService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/complain-types', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/complain-types', { ...data } )
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/complain-types/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/complain-types/${id}`)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async listAutocomplete(filters) {
        const params = { params: { ...filters, pagination: true, query: `status eq 'active'`} };
        const response = await ApiService.query('/complain-types', params);
        return response?.data || [];
    }
}

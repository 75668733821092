<template>
    <div>
        <Loader v-if="loading['complains/sendComment']"/>
        <st-page layout="tab" :showHeader="false">
            <complain-activities
                :items="activities"
                :hasPermissionToCreateComment="hasPermissionToCreateComment"
                @updateActivities="updateActivities"
            />
        </st-page>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ComplainPermissions } from '@/modules/complain/complain-permissions';
import ComplainActivities from '@/modules/complain/components/ComplainActivities';

export default {
    name: 'ComplainDiscussion',
    components: {
        ComplainActivities,
    },
    data() {
        return {
            polling: null,
        }
    },
    computed: {
        ...mapGetters({
            record: 'complain/form/record',
            activities: 'complain/activity/activities',
            currentUser: 'auth/currentUser',
            loading: 'shared/loading',
        }),
        hasPermissionToCreateComment() {
            return new ComplainPermissions(this.currentUser).comment;
        },
    },
    methods: {
        ...mapActions({
            getActivities: 'complain/activity/getActivities'
        }),
        updateActivities(complainId) {
            this.getActivities(complainId);
        }
    },
    async mounted() {
        if (this.record && this.record.id && !this.polling) {
            this.polling = setInterval(async () => {
                await this.getActivities(this.record.id);
            }, 5000);
        }
    },
    async created() {
        if (this.record && this.record.id) {
            await this.getActivities(this.record.id);
        }
    },
    beforeDestroy() {
        clearInterval(this.polling);
        this.polling = null;
    }
}
</script>
